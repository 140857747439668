import React from "react"
import __c from "../utilities/combineClasses"
import Img from "gatsby-image"

import style from "./home-banner.module.scss"

export default ({
  className,
  content,
  contentClassName,
  featureImage,
  featureImageAlt,
}) => {
  return (
    <div className={__c([style.component, className])}>
      <div className={__c([style.content, contentClassName])}>{content}</div>
      <div className={__c([style.image, style.aspectRatio_3_4])}>
        <Img fluid={featureImage} alt={featureImageAlt} />
      </div>
    </div>
  )
}
