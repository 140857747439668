import React from "react"
import { graphql, Link } from "gatsby"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeBanner from "../components/home-banner"
import BenefitBlurb from "../components/benefit-blurb"
import BenefitsBlock from "../components/benefits-block"

import style from "./index.module.scss"

const introduction = (
  <>
    <h2 className={__c(style.introductionHeader)}>Find Meaning in Your Marketing</h2>
    <p className={__c(style.introductionCopy)}>
      Marketing is more effective when you have the right tools to understand
      your campaigns&apos; performance. VariaMetrix cuts through the noise to
      focus on what affects your ROI. Our cross-platform drag-and-drop
      visualisation tool gives you immediate actionable insights and reports
      with just a click. This informative and interactive tool makes your
      analytics job more meaningful, profitable, and maybe even fun!
    </p>
    <div className={__c(style.cta)}>
      <Link className={__c(`aside-button`)} to="/contact">Schedule a demo</Link>
    </div>
  </>
)

export default ({ data }) => {
  const icons = data.icons.publicURL
  const bannerImage = data.bannerImage.childImageSharp.fluid
  return (
    <Layout
      isHome={true}
      banner={
        <HomeBanner
          featureImage={bannerImage}
          featureImageAlt="Example of VariaMetrix's drag-and-drop UI"
          content={introduction}
          contentClassName={style.homeIntro}
        />
      }
      className={style.gridLayout}
    >
      <SEO title="Home" />
      <div className={style.introduction}>{introduction}</div>
      {/* <FeatureVideo
        className={__c(style.featureVideo)}
        src={{
          mp4: `http://public.d2seattle.com/video/tong_1.mp4`,
          vtt: `/home-caption.vtt`,
        }}
      > 
        Take a quick tour with ___ as they manage their company&apos;s marketing
        better with VariaMetrix.
      </FeatureVideo>*/}
      <div className={__c(style.benefits)}>
        <BenefitBlurb title="Own your own data" icon={icons} iconOffset={0}>
          <p>
            VariaMetrix offers first-party tracking that ensures complete
            ownership of all your collected data. VariaMetrix even exports data
            it ingests from other tools so you can keep a copy for yourself.
          </p>
        </BenefitBlurb>
        <BenefitBlurb title="Flexible dashboards" icon={icons} iconOffset={1}>
          <p>
            Drag-and-drop interactivity and responsive filters make it easy to
            build VariaMetrix dashboards in seconds without having to work with
            clunky APIs. You never have to worry about losing your work because
            you can access your historical dashboards at any time.
          </p>
        </BenefitBlurb>
        <BenefitBlurb title="Automated reporting" icon={icons} iconOffset={2}>
          <p>
            VariaMetrix exports dashboards to PowerPoint, PDF, spreadsheets, and
            more. It lets you schedule reports for future processing and
            delivery so reports are always ready when you are.
          </p>
        </BenefitBlurb>
      </div>
      <BenefitsBlock className={__c(style.benefitsBlock)} />
    </Layout>
  )
}

export const query = graphql`
  query Images {
    icons: file(name: { eq: "iconset--home" }) {
      publicURL
    }
    bannerImage: file(name: { eq: "home-banner-image-2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
